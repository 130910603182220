import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Styled-components for styling
const DialogBox = styled.div`
  width: 1000px; /* Increased width */
  max-width: 95%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed; /* Changed from absolute to fixed */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensures it's above other elements */
  overflow-y: auto;
  max-height: 90vh;
  padding: 0;
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: white;
  color: black;
  border: 1px solid black;
  cursor: pointer;
  font-size: 16px;
`;

const DialogBody = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const ProductTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHeader = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  text-align: left;
  font-weight: bold;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

const DialogFooter = styled.div`
  padding: 20px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  background-color: #f5f5f5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;

  &:hover {
    background-color: #45a049;
  }

  &:first-child {
    background-color: #f44336;
    &:hover {
      background-color: #e53935;
    }
  }
`;

const DimensionStock = ({ productName, onClose,fetchLowStockProducts }) => {
  const [products, setProducts] = useState([]);
  const [updatedProducts, setUpdatedProducts] = useState({});

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/fetch-by-product-name?productName=${productName}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch product details");
        }
        const data = await response.json();
        if (data.length > 0) {
          setProducts(data);
          const initialUpdatedProducts = data.reduce((acc, product) => {
            acc[product._id] = { Price:product.Price,stock: product.stock, restock: product.restock };
            return acc;
          }, {});
          setUpdatedProducts(initialUpdatedProducts);
        } else {
          alert("No product found with that name.");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [productName]);

  // Handler to update product details
  const handleUpdate = async (productId) => {
    try {
      const { stock, restock, Price } = updatedProducts[productId];
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/update-details/${productId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ stock, restock , Price }),
        }
      );

      if (response.ok) {
        alert("Product updated successfully!");
        fetchLowStockProducts();
      } else {
        alert("Failed to update product. Please try again.");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  // Handler to manage changes in stock and restock
  const handleChange = (productId, field, value) => {
    setUpdatedProducts((prev) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        [field]: value,
      },
    }));
  };

  // if (products.length === 0) {
  //   return <div>Loading...</div>;
  // }

  return (
    <DialogBox>
      <DialogHeader>
        <h2>Update Product Details</h2>
        
      </DialogHeader>
      <DialogBody>
        <ProductTable>
          <thead>
            <tr>
              <TableHeader>Product Name</TableHeader>
              <TableHeader>Value 1</TableHeader>
              <TableHeader>Value 2</TableHeader>
              <TableHeader>Price</TableHeader>
              <TableHeader>Stock</TableHeader>
              <TableHeader>Restock</TableHeader>
              <TableHeader>Actions</TableHeader>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id}>
                <TableCell>{product.productName}</TableCell>
                <TableCell>{product.Value1}</TableCell>
                <TableCell>{product.Value2}</TableCell>
                <TableCell><input
                    type="number"
                    value={updatedProducts[product._id]?.Price || ""}
                    onChange={(e) =>
                      handleChange(product._id, "Price", Number(e.target.value))
                    }
                  /></TableCell>
                <TableCell>
                  <input
                    type="number"
                    value={updatedProducts[product._id]?.stock || ""}
                    onChange={(e) =>
                      handleChange(product._id, "stock", Number(e.target.value))
                    }
                  />
                </TableCell>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={updatedProducts[product._id]?.restock || false}
                    onChange={(e) => handleChange(product._id, "restock", e.target.checked)}
                  />
                </TableCell>
                <TableCell>
                  <ActionButton onClick={() => handleUpdate(product._id)}>Update</ActionButton>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </ProductTable>
      </DialogBody>
      <DialogFooter>
      <CloseButton onClick={onClose}>Close</CloseButton>
      </DialogFooter>
    </DialogBox>
  );
};

export default DimensionStock;
